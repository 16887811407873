import React from 'react'
import { ShareData, ToolbarButtonTypes } from '@client/types'
import { StyleContext } from '@client/types'

import { ToolbarContainerButtons } from './ToolbarContainerButtons'
import { ToolbarContainerShare } from './ToolbarContainerShare'

/*
 * Тулбар с кнопками шеров и кнопкой закладки, который появляется при
 * скролле материала сверху (в TopBar)
 * и есть внизу каждого материала
 */

export interface ToolbarContainerProps {
  url?: string
  place: string
  buttons: ShareData
  lang: 'ru' | 'en'
  pdf?: string
  styleContext?: StyleContext
}

export const ToolbarContainer: React.FC<ToolbarContainerProps> = ({
  url,
  buttons,
  lang,
  styleContext,
  place
}) => {
  const actualButtons: {
    type: ToolbarButtonTypes
    label?: string
    url?: string
  }[] = []

  if (buttons.share) {
    actualButtons.push(
      {
        type: 'tg'
      },
      {
        type: 'fb'
      },
      {
        type: 'tw'
      }
    )
  }

  if (buttons.bookmark) {
    actualButtons.push({
      type: 'bookmark'
    })
  }

  if (buttons.pdf) {
    actualButtons.push({
      type: 'pdf'
    })
  }

  if (place === 'bottom') {
    actualButtons.push({
      type: 'reaction'
    })
  }

  if (buttons.unblock) {
    actualButtons.push({
      type: 'unblock'
    })
  }

  return (
    <>
      {lang === 'ru' ? (
        <ToolbarContainerButtons
          buttons={actualButtons}
          place={place}
          lang="ru"
          styleContext={styleContext}
          url={url}
        />
      ) : (
        <ToolbarContainerShare place={place} styleContext={styleContext} />
      )}
    </>
  )
}
