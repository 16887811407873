import { utmMedium, utmSource } from '@client/constants'
import { MaterialContent, ScreenContent, ShareServices } from '@client/types'
import { popupWindow } from '@utils/popupWindow'

const targets: Record<
  string,
  {
    counter?: string
    share: string
    response?: string
  }
> = {
  fb: {
    counter: '//graph.facebook.com/{{href}}',
    share: '//www.facebook.com/sharer/sharer.php?u={{href}}',
    response: 'shares'
  },

  tw: {
    counter: '//cdn.api.twitter.com/1/urls/count.json?url={{href}}',
    share: '//twitter.com/intent/tweet?text={{text}}&url={{href}}&via={{via}}',
    response: 'count'
  },

  vk: {
    counter: '//vk.com/share.php?act=count&url={{href}}',
    share: '//vk.com/share.php?url={{href}}',
    response: 'count'
  },

  ok: {
    counter: '//connect.ok.ru/dk?st.cmd=extLike&ref={{url}}&uid=0',
    share: '//connect.ok.ru/offer?url={{href}}',
    response: 'count'
  },

  tg: {
    share:
      '//telegram.me/share/url?url={{href}}&text=Канал%20«Медузы»:%20@meduzalive'
  },

  wp: {
    share: 'whatsapp://send?text={{href}}'
  }
}

export const sharePopup = (
  service: ShareServices,
  material: MaterialContent | ScreenContent
) => {
  if (service === 'pdf') {
    const path = window.location.origin + material.og.pdf?.standard.path

    window.open(path, 'Download')

    return
  }

  const via = 'meduzaproject'
  const [origin] = window.location.href.split('?')
  let href = origin

  href += `?utm_source=${utmSource[service]}`
  href += `&utm_medium=${utmMedium[service]}`
  href += '&utm_campaign=share'

  let url = targets[service].share.replace(
    /\{\{href\}\}/,
    window.encodeURIComponent(href)
  )

  url = url.replace(/\{\{text\}\}/, window.encodeURIComponent(document.title))
  url = url.replace(/\{\{via\}\}/, via)

  if (service === 'vk' && material && material.og && material.og.image) {
    const imageSrc = `${material.og.image.replace('/share/', '/vk_share/')}`
    url += `&image=${imageSrc}`
  }

  popupWindow(url, 'Share', 480, 320)
}
