import React from 'react'
import { useDispatch } from 'react-redux'
import { StyleContext } from '@client/types'
import { makeClassName } from '@client/utils/makeClassName'
import { callToAction } from '@store/App/appActions'

import { Toolbar } from '@meduza/ui-kit-2'

import styles from './ToolbarContainer.module.css'

/*
 * Тулбар с кнопками шеров и кнопкой закладки, который появляется при
 * скролле материала сверху (в TopBar)
 * и есть внизу каждого материала
 */

export interface ToolbarContainerShareProps {
  place: string
  styleContext?: StyleContext
}

export const ToolbarContainerShare: React.FC<ToolbarContainerShareProps> = ({
  styleContext,
  place
}) => {
  const dispatch = useDispatch()

  const isDark = styleContext && styleContext.indexOf('dark') > -1

  return (
    <Toolbar styleContext={styleContext}>
      <li
        className={makeClassName([
          [styles.share, true],
          [styles.isDark, !!isDark]
        ])}
      >
        Share to{' '}
        <button
          className={styles.button}
          onClick={() =>
            dispatch(
              callToAction({
                place,
                service: 'fb'
              })
            )
          }
        >
          Facebook
        </button>{' '}
        or{' '}
        <button
          className={styles.button}
          onClick={() =>
            dispatch(
              callToAction({
                place,
                service: 'tw'
              })
            )
          }
        >
          Twitter
        </button>
      </li>
    </Toolbar>
  )
}
